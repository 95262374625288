<template>
  <div class="dsh">
    <beacon-banner
      :bannerSrc="banner_6"
      title="大食慧收银系统  新一代智能数据化平台"
      subTitle="New  generation  of  data platform"
    ></beacon-banner>
    <div class="section section_1">
      <div class="title">支持全业态餐饮类型</div>
      <div class="content">
        <ul class="type_list">
          <li class="type_item" v-for="(type, index) in typeList" :key="index">
            <div style="overflow: hidden; border-radius: 50%">
              <img class="type_img" :src="type.img" alt="" />
            </div>
            <div class="type_title">{{ type.title }}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="section section_2">
      <div class="title">大食慧云餐饮</div>
      <div class="content">
        <div class="desc">
          大食慧云餐饮，包含门店端的收银POS/排队/预订/报表/会员/库存功能，集团端的人事管理/CRM管理/财务管理/LPS会员计划/
          BI大数据分析功能，采购/库存/盘点/中央厨房/物流配送等供应链管理功能，并打通微信/外卖平台的接口，完善移动端应用。随着餐饮行业竞争加剧，品牌化、连锁化经营成为未来发展趋势，大食慧愿携手餐饮经营者，共同搭建餐饮数据化云平台，为餐饮企业发展保驾护航！
        </div>
        <div class="scroll">
          <ul class="edition_list">
            <li
              class="edition_item"
              v-for="(edition, index) in editionList"
              :key="index"
            >
              <div class="edition_content">
                <div class="curtain">
                  <div class="curtain_title">{{ edition.title }}</div>
                  <p class="curtain_content">
                    {{ edition.curtain }}
                  </p>
                </div>
                <div class="curtains"></div>
                <img class="edition_img" :src="edition.img" alt="" />
                <div class="edition_name">{{ edition.title }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="section section_3">
      <div class="title">大食慧云餐饮特色</div>
      <div class="content">
        <ul class="character_list">
          <li
            class="character_item"
            v-for="(character, index) in characterList"
            :key="index"
          >
            <div class="img_wrap text-center">
              <img class="character_img" :src="character.img" alt="" />
            </div>

            <div class="character_name">{{ character.title }}</div>
            <div class="character_desc">{{ character.desc }}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="section section_4">
      <div class="title">服务的品牌客户</div>
      <div class="content">
        <ul class="customer_list">
          <li
            class="customer_item"
            v-for="(customer, index) in customerList"
            :key="index"
          >
            <div class="bg_white">
              <img class="customer_img" :src="customer.img" alt="" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { banner_6,
  type_1,
  type_2 ,
type_3 ,
type_4 ,
type_5 ,
type_6 ,
type_7 ,
type_8 ,
type_9 ,
type_10, } from "@/utils/images";
export default {
  data() {
    return {
      banner_6,
      typeList: [
        {
          img: type_1,
          title: "中餐",
        },
        {
          img: type_2,
          title: "西餐",
        },
        {
          img: type_3,
          title: "快餐",
        },
        {
          img: type_4,
          title: "火锅店",
        },
        {
          img: type_5,
          title: "饮品店",
        },
        {
          img: type_6,
          title: "美食城",
        },
        {
          img: type_7,
          title: "茶楼",
        },
        {
          img: type_8,
          title: "咖啡厅",
        },
        {
          img: type_9,
          title: "酒吧",
        },
        {
          img: type_10,
          title: "棋牌室",
        },
      ],
      editionList: [
        {
          img: require("@/assets/images/edition_1.png"),
          title: "快捷版Express edition",
          curtain:
            "随着餐饮消费升级，餐饮企业经营业态也在快速转型，更趋向于大众化、轻业态、连锁化发展。近几年来快餐、咖啡、饮品等轻餐饮发展迅速，大食慧云餐饮软件，简化传统餐饮冗余的环节、优化流程、打通第三方平台接口，软件操作方便快捷，极大提高餐厅效率。",
        },
        {
          img: require("@/assets/images/edition_2.png"),
          title: "基础版Foundation edition",
          curtain:
            "当下餐饮行业成本持续上升、人员流动性大，传统餐厅发展受制于人。大食慧云餐饮软件优化餐厅流程，通过顾客扫码点餐、扫码支付、厨房出单、会员管理、对接三方平台等功能，让软件操作更加方便快捷，极大提高餐厅效率、增强客户体验、降低餐厅运营成本。",
        },
        {
          img: require("@/assets/images/edition_3.png"),
          title: "商务版Business edition",
          curtain:
            "基于15年的行业经验和技术背景，在B/S架构的管理软件的基础上，利用云计算、大数据分析，为传统餐厅经营提供有力支撑，降低运营成本；并结合网络营销、电子商务帮助传统餐饮插上互联网的翅膀，实现智慧餐厅的转型升级！",
        },
        {
          img: require("@/assets/images/edition_4.png"),
          title: "集团版Group edition",
          curtain:
            "大食慧集团版后台功能与分店功能相配套，一起为连锁餐饮集团实现集团化管理提供技术支持，具备集团会员管理、人力资源管理、财务管理、运营管理、加盟管理、物流配送、集团BI大数据分析等强大功能，帮助连锁餐饮企业规范内部管理。",
        },
      ],
      characterList: [
        {
          img: require("@/assets/images/character_1.png"),
          title: "互联网+餐饮",
          desc: "全面连接互联网平台，对接交易、查 询、服务、评价等应用场景",
        },
        {
          img: require("@/assets/images/character_2.png"),
          title: "软件操作",
          desc: "软件功能划分思想完全基于数据流，更加关注操作的流畅与便捷",
        },
        {
          img: require("@/assets/images/character_3.png"),
          title: "客户自助服务",
          desc:
            "支持扫码点餐、扫码支付，点餐方便快捷，提高翻台率，降低人力成本",
        },
        {
          img: require("@/assets/images/character_4.png"),
          title: "支付方式",
          desc:
            "支持现金/银行卡/会员卡/组合支付/扫码支付，支持现金券/优惠券/电子券抵扣",
        },
        {
          img: require("@/assets/images/character_5.png"),
          title: "一键沽清",
          desc: "支持手动沽清/智能沽清，前后台实时更新，库存情况及时掌控",
        },
        {
          img: require("@/assets/images/character_6.png"),
          title: "门店进销存",
          desc: "库存盘点快速准确，要货及时精准，配送验收、 退货调拨功能齐全",
        },
        {
          img: require("@/assets/images/character_7.png"),
          title: "移动应用",
          desc:
            "各角色移动应用齐全，从点餐、支付到报表、采购，真正实现掌上办公，优化流程，高效运营",
        },
        {
          img: require("@/assets/images/character_8.png"),
          title: "技术服务",
          desc:
            "现场培训，本地化服务，实时远程协助解决问题，解决您的所有后顾之忧",
        },
      ],
      customerList: [],
    };
  },
  created() {
    for (let index = 1; index <= 20; index++) {
      this.customerList.push({
        img: require("@/utils/images")[`customer_${index}`],
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.section_1 {
  .content {
    padding-left: 9.025%;
    padding-right: 9.025%;
  }
  .type_list {
    overflow: hidden;
    margin: 0 -1.78%;
    margin-top: -7.14%;
  }
  .type_item {
    float: left;
    width: 20%;
    padding: 0 2.78%;
    padding-top: 7.14%;
    .type_img {
      display: block;
      width: 100%;
      transition: 0.5s;
      &:hover {
        transform: scale(1.1);
      }
    }

    .type_title {
      text-align: center;
      margin-top: 15.19%;
      font-size: 1rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000000;
    }
  }
}

.section_2 {
  background-color: #f5f8fa;
  .desc {
    font-size: 19px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 40px;
    color: #333333;
    text-indent: 2em;
  }
  .scroll {
    margin: 0 -1.44%;
    overflow-x: auto;
  }
  .edition_list {
    margin-top: 5.78%;
    white-space: nowrap;
    .edition_item {
      display: inline-block;
      min-width: 183px;
      width: 25%;
      padding: 1.44%;
      position: relative;
      &:hover {
        .curtain {
          height: 100% !important;
        }
      }
      .edition_content {
        position: relative;
        white-space: normal;
        .curtain {
          position: absolute;
          width: 100%;
          transition: 0.5s;
          overflow: hidden;
          top: 0;
          left: 0;
          background-color: rgba(3, 110, 184, 0.8);
          height: 0;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .curtain_title {
            text-align: center;
            margin: 0.6rem 0;
            font-size: 1.1rem;
            font-weight: bolder;
          }
          .curtain_content {
            margin: 0 1rem;
            font-size: 0.8rem;
            line-height: 1.5rem;
          }
        }
      }
      .curtains {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      &:nth-child(1) {
        margin-left: 0;
      }
      .edition_img {
        width: 100%;
      }
      .edition_name {
        padding: 24px 0;
        text-align: center;
        background-color: #fff;
        font-size: 1rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}

.section_3 {
  .character_list {
    overflow: hidden;
    margin: 0 -4.38%;
    margin-top: -9.25%;
    display: flex;
    flex-wrap: wrap;
    .character_item {
      width: 25%;
      padding: 0 4.38%;
      text-align: center;
      padding-top: 9.25%;
      .character_img {
        display: inline-block;
        width: 51px;
        height: 51px;
      }
      .character_name {
        font-size: 19px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
      .character_desc {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 19px;
        color: #333333;
        margin-top: 12px;
      }
      .img_wrap {
        padding: 19.7% 0 11.82% 0;
      }
    }
  }
}

.section_4 {
  background: #f5f8fa;
  .customer_list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;
    .customer_item {
      align-self: stretch;
      width: 20%;
      padding: 4px;
      overflow: hidden;
      @media screen and (max-width: 1200px) {
        width: 25%;
      }
      .bg_white {
        background-color: #fff;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 11px 0;
      }
      .customer_img {
        max-width: 45.11%;
        flex-shrink: 0;
      }
      .customer_title {
        margin-top: 4.89%;
        font-size: 19px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 29px;
        color: #262727;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .section_1 {
    .title {
      padding-bottom: 30vpx;
    }
    .content {
      padding: 0 13vpx;
    }
    .type_list {
      margin: 0 -13vpx;
      margin-top: -20vpx;
    }
    .type_item {
      padding: 0 13vpx;
      padding-top: 20vpx;

      .type_title {
        margin-top: 10vpx;
        font-size: 12vpx;
      }
    }
  }

  .section_2 {
    background-color: #fff;
    .title {
      padding-bottom: 30vpx;
    }
    .content {
      padding: 0 15vpx;
    }
    .desc {
      font-size: 14vpx;
      line-height: 24vpx;
    }
    .scroll {
      margin: 0 -7.5vpx;
      margin-top: 20vpx;
    }
    .edition_list {
      margin-top: -7.5vpx;
      .edition_item {
        padding: 7.5vpx;
        width: 215vpx;
        .edition_img {
          display: block;
        }
        .edition_name {
          font-size: 14vpx;
          padding: 13vpx 0;
          text-align: center;
          background-color: #f4f4f4;
        }
      }
    }
  }

  .section_3 {
    .title {
      padding-bottom: 30vpx;
    }
    .content {
      padding: 0 15vpx;
    }
    .character_list {
      margin: 0;
      margin-top: -5vpx;
      .character_item {
        background-color: #f5f8fa;
        width: calc(50% - 2.5vpx);
        margin-top: 5vpx;
        padding: 30vpx 20vpx 30vpx 20vpx;
        &:nth-child(2n + 1) {
          margin-right: 2.5vpx;
        }
        &:nth-child(2n) {
          margin-left: 2.5vpx;
        }
        .character_img {
          width: 48vpx;
          height: 48vpx;
          object-fit: contain;
        }
        .character_name {
          margin-top: 30vpx;
          font-size: 14vpx;
          font-weight: 500;
        }
        .character_desc {
          margin-top: 15vpx;
          font-size: 12vpx;
          line-height: 20vpx;
          color: #666666;
        }
        .img_wrap {
          padding: 0;
        }
      }
    }
  }

  .section_4 {
    background: #fff;
    .content {
      padding: 0 15vpx;
      padding-bottom: 40vpx;
    }
    .customer_list {
      margin: -2.5vpx -2.5vpx 0 -2.5vpx;
      .customer_item {
        padding: 2.5vpx;
        width: 50%;
        .bg_white {
          border: 1px solid #e1e1e1;
          padding: 5vpx 0;
        }
        .customer_img {
          max-width: 82vpx;
        }
      }
    }
  }
}
</style>